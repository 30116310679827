import pluralize from "pluralize";

import { capitalizeWords } from "utils/case";

export default function cleanEntityType(
  entityType,
  plural = false,
  short = false
) {
  if (!entityType) {
    return entityType;
  }
  let newType = pluralize.singular(capitalizeWords(entityType.toLowerCase()));
  switch (newType) {
    case "Userlist":
      newType = "List";
      break;
    case "Episode":
      if (!short) {
        newType = "Episode";
      }
      break;
  }
  return plural ? pluralize(newType) : newType;
}
