import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";

import ShareButton from "components/Buttons/Items/ShareButtonAsync";

import EpisodeTypeBadge from "../../Podcast/EpisodeTypeBadgeContainer";
import CardTitleLine from "./CardTitleLine";

import cleanEntityType from "utils/entity/cleanEntityType";

import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  outer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inner: {
    display: "flex",
    flexDirection: "column",
  },
  secondaryRow: {
    ...cardStyles.episodePodcastName,
    ...cardStyles.episodePodcastNamePosition,
  },
  titleContainer: {
    ...cardStyles.mobileTitleContainer,
  },
  title: {
    ...cardStyles.mobileCardPrimaryTitle,
    display: "inline-flex", // This fixes weird height differences in spans, and still allows username next to it
    flexDirection: "row",
  },
  shareContainer: {
    ...cardStyles.mobileShareContainer,
  },
};

const defaultTitleStyles = {
  entityLink: {
    ...cardStyles.desktopCardPrimaryTitle,
    whiteSpace: "normal",
  },
};

const defaultSecondaryTitleStyles = {
  link: {
    ...gStyles.textEllipsis,
  },
  entityLink: {
    ...gStyles.textEllipsis,
  },
};

const episodeBadgeStyles = {
  outer: {
    margin: "0.025em 0.5rem 0.375rem 0",
    alignSelf: "flex-start",
  },
};

const CardTitle = (props) => {
  const {
    additionalContent,
    className,
    entity,
    entityLineProps,
    entityLinkProps,
    entityType,
    hidePowerScore,
    hideSecondaryButtons,
    inlineContent,
    podcast,
    renderOnMount,
    secondaryAdditionalContent,
    secondaryEntity,
    secondaryEntityLineProps,
    secondaryEntityLinkProps,
    secondaryEntityType,
    additionalStyles,
    showHasGuests,
    titleStyles,
    showIsVerified,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const finalEntityLinkProps = useMemo(
    () => ({ clamp: 2, ...entityLinkProps }),
    [entityLinkProps]
  );

  const renderEntityLine = () => (
    <CardTitleLine
      additionalContent={additionalContent}
      badgeSize={13}
      dataId="entity-card-title"
      entity={entity}
      entityLinkProps={finalEntityLinkProps}
      styles={titleStyles || defaultTitleStyles}
      entityType={entityType}
      hidePowerScore={hidePowerScore}
      renderOnMount={renderOnMount}
      showAuthority={!secondaryEntity}
      showHasGuests={showHasGuests}
      showIsVerified={showIsVerified}
      {...entityLineProps}
    />
  );

  const renderSecondaryEntityLine = () => (
    <CardTitleLine
      additionalContent={secondaryAdditionalContent}
      badgeLabelSize={11}
      badgeSize={12}
      entity={secondaryEntity}
      entityLinkProps={secondaryEntityLinkProps}
      styles={additionalStyles || defaultSecondaryTitleStyles}
      entityType={secondaryEntityType}
      hidePowerScore={hidePowerScore}
      showAuthority
      showHasGuests={showHasGuests}
      renderOnMount={renderOnMount}
      showIsVerified={showIsVerified}
      {...secondaryEntityLineProps}
    />
  );

  const name =
    entity?.get("informal_name") ||
    entity?.get("display_name") ||
    entity?.get("title") ||
    entity?.get("name") ||
    entity?.get("username");
  const shareLabel =
    entityType === "user" || entityType === "creator"
      ? `Share ${name}'s Profile`
      : `Share ${cleanEntityType(entityType)}`;

  const items = [];
  const innerSubItems = [];
  const innerTitleSubItems = [];

  if (secondaryEntity) {
    innerSubItems.push(
      <div key="secondaryRow" className={css(styles.secondaryRow)}>
        {renderSecondaryEntityLine()}
      </div>
    );
  }
  if (entityType === "episode") {
    innerTitleSubItems.push(
      <EpisodeTypeBadge
        key="episodeBadge"
        episode={entity}
        styles={episodeBadgeStyles}
      />
    );
  }

  innerTitleSubItems.push(
    <p key="title" className={css(styles.title)}>
      {renderEntityLine()}
    </p>
  );

  if (innerTitleSubItems.length > 1) {
    innerSubItems.push(
      <div key="titleContainer" className={css(styles.titleContainer)}>
        {innerTitleSubItems}
      </div>
    );
  } else {
    innerSubItems.push(innerTitleSubItems[0]);
  }

  if (innerSubItems.length > 1) {
    items.push(
      <div key="subItems" className={css(styles.inner)}>
        {innerSubItems}
      </div>
    );
  } else {
    items.push(innerSubItems[0]);
  }

  if (!hideSecondaryButtons) {
    items.push(
      <div key="shareButton" className={css(styles.shareContainer)}>
        <ShareButton
          entity_type={entityType}
          entity={entity}
          small
          podcast={podcast}
          buttonTooltip={shareLabel}
        />
      </div>
    );
  }
  if (inlineContent) {
    items.push(inlineContent);
  }

  if (items.length > 1) {
    return (
      <div key="CardTitleItems" className={`${css(styles.outer)} ${className}`}>
        {items}
      </div>
    );
  }

  return items[0];
};

CardTitle.propTypes = {
  additionalContent: PropTypes.node,
  className: PropTypes.string,
  entity: PropTypes.object.isRequired,
  entityLineProps: PropTypes.object,
  entityLinkProps: PropTypes.object,
  entityType: PropTypes.string.isRequired,
  hidePowerScore: PropTypes.bool,
  hideSecondaryButtons: PropTypes.bool,
  inlineContent: PropTypes.node,
  podcast: PropTypes.object,
  renderOnMount: PropTypes.bool,
  secondaryAdditionalContent: PropTypes.node,
  secondaryEntity: PropTypes.object,
  secondaryEntityLineProps: PropTypes.object,
  secondaryEntityLinkProps: PropTypes.object,
  secondaryEntityType: PropTypes.string,
  additionalStyles: PropTypes.object,
  showHasGuests: PropTypes.bool,
  titleStyles: PropTypes.object,
};

CardTitle.defaultProps = {
  additionalContent: undefined,
  className: "",
  entityLineProps: {},
  entityLinkProps: {},
  hidePowerScore: false,
  hideSecondaryButtons: false,
  inlineContent: null,
  podcast: undefined,
  renderOnMount: false,
  secondaryAdditionalContent: undefined,
  secondaryEntity: undefined,
  secondaryEntityLineProps: {},
  secondaryEntityLinkProps: {},
  secondaryEntityType: undefined,
  additionalStyles: {},
  showHasGuests: false,
  titleStyles: {},
};

export default memo(CardTitle);
