import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, Fragment } from "react";
import { Link } from "react-router-dom";

import HeaderVerifiedBadge from "../../../pages/PodcastView/HeaderVerifiedBadge";
import AuthorityBadge from "../../Podcast/AuthorityBadgeAsync";
import ClaimedBadge from "../../Podcast/ClaimedBadgeAsync";
import HasGuestsBadge from "../../Podcast/HasGuestsBadgeAsync";
import EntityLink from "../EntityLink";

import getPodcastAuthorityLevel from "utils/entity/getPodcastAuthorityLevel";

import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  outer: {},
};

const defaultTitleStyles = {
  outer: {},
  link: {},
  internalLink: {},
  externalLink: {},
  entityLink: {
    ...cardStyles.desktopCardPrimaryTitle,
    whiteSpace: "normal",
  },
};

const hasGuestsStyles = {
  hasGuestsBadge: {
    marginLeft: "0.5rem",
  },
};

const verifiedBadgeStyles = {
  verifiedBadge: {
    height: 16,
    width: "2rem",
    marginTop: "-0.2rem",
    [ScreenSizes.lgAndAbove]: {
      height: 16,
    },
  },
};

const CardTitleLine = (props) => {
  const {
    additionalContent,
    badgeLabelSize,
    badgeSize,
    dataId,
    entity,
    entityLinkProps,
    entityType,
    external,
    hidePowerScore,
    link,
    renderOnMount,
    showAuthority,
    showHasGuests,
    titleStyles,
    showIsVerified,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  if (!entity) {
    return null;
  }

  const isClaimed =
    entity.get("is_claimed") ||
    (entityType === "creator" && entity.get("user"));

  const renderLink = () => {
    const linkContent = (
      <Fragment>
        {entity.get("display_name") ||
          entity.get("title") ||
          entity.get("name") ||
          entity.get("username")}
        {additionalContent}
      </Fragment>
    );

    if (link) {
      if (external) {
        return (
          <a
            key="entityLink"
            className={css(styles.link, styles.externalLink)}
            href={link}
            rel="noopener noreferrer"
            target="_blank"
          >
            {linkContent}
          </a>
        );
      }

      return (
        <Link
          key="entityLink"
          to={link}
          className={css(styles.link, styles.internalLink)}
        >
          {linkContent}
        </Link>
      );
    }

    return (
      <EntityLink
        key="entityLink"
        dataId={dataId}
        entity={entity}
        entity_type={entityType}
        styles={titleStyles || defaultTitleStyles}
        noLinkFont
        inline={
          entityLinkProps?.inline !== undefined ? entityLinkProps.inline : true
        }
        renderOnMount={renderOnMount}
        {...entityLinkProps}
      >
        {linkContent}
      </EntityLink>
    );
  };

  const items = [renderLink()];

  if (isClaimed) {
    items.push(
      <ClaimedBadge
        key="claimedBadge"
        showLabel={false}
        size={badgeSize}
        showTooltip
      />
    );
  }

  if (showIsVerified && entityType === "podcast") {
    items.push(
      <HeaderVerifiedBadge styles={verifiedBadgeStyles} podcast={entity} />
    );
  }

  if (entityType === "podcast" && showAuthority && !hidePowerScore) {
    items.push(
      <AuthorityBadge
        key="authorityBadge"
        entity_type={entityType}
        entity={entity}
        score={getPodcastAuthorityLevel(entity, 0)}
        size={badgeSize}
        labelSize={badgeLabelSize}
        tooltipText={`Power Score: ${getPodcastAuthorityLevel(entity, 2)}`}
        showTooltip
      />
    );
  }

  if (entityType === "podcast" && showAuthority && showHasGuests) {
    items.push(
      <HasGuestsBadge
        entity={entity}
        key="hasGuestsBadge"
        size={badgeSize}
        styles={hasGuestsStyles}
      />
    );
  }

  if (items.length > 1) {
    return (
      <span key="cardTitleLine" className={css(styles.outer)}>
        {items}
      </span>
    );
  }

  return items[0];
};

CardTitleLine.propTypes = {
  additionalContent: PropTypes.node,
  badgeLabelSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  badgeSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataId: PropTypes.string,
  entity: PropTypes.instanceOf(Map),
  entityLinkProps: PropTypes.object,
  entityType: PropTypes.string,
  external: PropTypes.bool,
  hidePowerScore: PropTypes.bool,
  link: PropTypes.string,
  renderOnMount: PropTypes.bool,
  showAuthority: PropTypes.bool,
  showHasGuests: PropTypes.bool,
  titleStyles: PropTypes.object,
};

CardTitleLine.defaultProps = {
  additionalContent: null,
  badgeLabelSize: undefined,
  badgeSize: undefined,
  dataId: null,
  entity: null,
  entityLinkProps: {},
  entityType: null,
  external: false,
  hidePowerScore: false,
  link: null,
  renderOnMount: false,
  showAuthority: false,
  showHasGuests: false,
  titleStyles: null,
};

export default memo(CardTitleLine);
