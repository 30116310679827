import { Map } from "immutable";
import PropTypes from "prop-types";
import React from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import verifiedImage from "./svg/verified.svg";

import getVerifiedData from "utils/verifiedBanner/getVerifiedData";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  verifiedBadge: {
    height: 24,
    width: "2.375rem",
    marginRight: "0.7rem",

    [ScreenSizes.lgAndAbove]: {
      height: 26,
      marginRight: "1rem",
    },
  },
};

export default function HeaderVerifiedBadge(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { podcast } = props;

  const { verifiedReason, isVerified } = getVerifiedData(podcast);

  if (!isVerified) {
    return null;
  }

  return (
    <BasicTooltip renderTooltip={() => verifiedReason}>
      {(tooltipProps) => (
        <img
          data-testid="verifiedIcon"
          {...tooltipProps}
          src={verifiedImage}
          alt="verified Badge"
          className={css(styles.verifiedBadge)}
        />
      )}
    </BasicTooltip>
  );
}

HeaderVerifiedBadge.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
};
