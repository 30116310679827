import { floorToDecimalPlaces } from "utils/misc";

const MILLION = 1000000;
const THOUSAND = 1000;

const getShortenNumber = (num, options = {}) => {
  // TODO Fix roundTo issue on creator page
  const { divider, roundTo = 1, shortenHigh = false } = options;
  const dividedNum = num / divider;
  const floored = floorToDecimalPlaces(dividedNum, roundTo);
  if (shortenHigh && `${floored}`.length > 4) {
    return Math.floor(floored);
  }
  return floored;
};

export default function addMetricPostfix(num, options = {}) {
  const { roundTo = 1, shortenHigh = false } = options;
  if (num >= MILLION) {
    return `${getShortenNumber(num, {
      divider: MILLION,
      roundTo,
      shortenHigh,
    })}m`;
  }
  if (num >= THOUSAND) {
    return `${getShortenNumber(num, {
      divider: THOUSAND,
      roundTo,
      shortenHigh,
    })}k`;
  }

  return num;
}
