import addMetricPostfix from "../../../../utils/misc/addMetricPostfix";

import getEpisodeUrl from "utils/entity/getEpisodeUrl";

export const getIcons = (episode) =>
  [
    /* (episode.get('rating_count') && {
    type: 'ratings',
    text: episode.get('rating_count'),
    to: getEpisodeUrl(episode),
  }), */
    episode.get("review_count") && {
      type: "reviews",
      value: episode.get("review_count"),
      text: addMetricPostfix(episode.get("review_count")),
      to: getEpisodeUrl(episode, { path: "/reviews" }),
    },
    episode.get("list_count") && {
      type: "lists",
      value: episode.get("list_count"),
      text: addMetricPostfix(episode.get("list_count")),
      to: getEpisodeUrl(episode, { path: "/lists" }),
    },
    episode.get("air_date") && {
      type: "aired",
      value: episode.get("air_date"),
      to: getEpisodeUrl(episode),
    },
    episode.get("length") && {
      type: "length",
      value: episode.get("length"),
      to: getEpisodeUrl(episode),
    },
  ].filter((iconType) => iconType);
