import Loadable from "@loadable/component";
const CardCreators = Loadable(() => import("./CardCreators"));

function CardCreatorsAsyncLoading() {
  return null;
}

const CardCreatorsContainer = (props) => {
  return <CardCreators {...props} fallback={<CardCreatorsAsyncLoading />} />;
};

export default CardCreatorsContainer;
