import Loadable from "@loadable/component";
const HasGuestsBadge = Loadable(() => import("./HasGuestsBadge"));

function HasGuestsBadgeAsyncLoading() {
  return null;
}

const HasGuestsBadgeContainer = (props) => {
  return (
    <HasGuestsBadge {...props} fallback={<HasGuestsBadgeAsyncLoading />} />
  );
};

export default HasGuestsBadgeContainer;
