export default function getVerifiedData(podcast) {
  const isVerified = podcast?.getIn([
    "reach_estimate_data",
    "manual_reach_verified",
  ]);

  const verifiedBy = podcast?.getIn([
    "reach_estimate_data",
    "manual_reach_provider",
  ]);

  const verifiedReason = podcast?.getIn([
    "verified_reach_reasons",
    0,
    "verification_reason",
  ]);

  return {
    isVerified,
    verifiedBy,
    verifiedReason,
  };
}
